import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => {
    return {
        markdownContent: {
            fontSize: 16,
            padding: theme.spacing(4, 0, 12),
            '& h2': {
                fontSize: 18,
                fontWeight: 'bold',
            },
            '& ul': {
                listStyle: 'unset',
            },
            '& li': {
                '& > p': {
                  margin: 0,
                },
                '& > ol': {
                  listStyleType: 'lower-alpha',
                }
            },
            '& > *': {
                fontSize: 'inherit',
            }
        },
    };
});