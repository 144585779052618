import React from 'react';
import Layout from 'components/layout/main';
import PageTitle from 'components/page/title';
import makeStyles from './markdown-page.css.js';

const MarkdownPage = (props) => {

    const { data, pageContext } = props;

    const classes = makeStyles();

    return (
        <Layout containerSize="md">
            <PageTitle navOffset>{pageContext.frontmatter.title}</PageTitle>
            <main
                className={classes.markdownContent}
                dangerouslySetInnerHTML={{__html: pageContext.html}}
            />
        </Layout>
    );

}

export default MarkdownPage;